import { observer } from 'mobx-react-lite';
import Page from './page';
import './style.css';

const Right = ({ action, current, list }) => {
	let cls = ['skin-ms-ttl-right', 'ms-ttl-right'];
	cls = cls.join(' ');
	const max = list.length - 1;
	const st = { backgroundImage: 'url(skin/img/close.svg)' };
	return (
		<div className={cls}>
			<div>
				{list.map((el, i) => (
					<Page
						key={i}
						data={el}
						item={i}
						current={current}
						max={max}
						action={action}
					/>
				))}
			</div>
			<span className="ico-r" style={st} onClick={close}></span>
		</div>
	);
	function close() {
		const o = {
			type: 'master',
			action: 'exit',
		};
		action(o);
	}
};

export default observer(Right);
