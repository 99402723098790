import { observer } from 'mobx-react-lite'
import dict from 'skin/store/dict'
import Itm from './itm'
import './style.css'

const Head = props => {
	let cls = [
		'skin-dct-cnt-main-head',
		'dct-cnt-main-head'
	]
	cls = cls.join(' ')
	const w = dict.s.width ?? {}
	let a = dict.s.head ?? ['Значение']
	if (a === 'code') a = ['Код', 'Значение']
	return (
		<table className={cls}>
			<thead>
				<tr>
					{a.map((el, i) => <Itm key={i} data={el} item={i} width={w} />)}
				</tr>
			</thead>
		</table>
	)
}

export default observer(Head)
