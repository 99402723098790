import { useEffect, useState } from 'react';
import Error from '../error';
import Label from './lable';
import './style.css';

const InputColor = ({ s, v, e, setValue }) => {
	const {
		_id,
		name,
		off,
		title = '',
		disabled = false,
		width = '180px',
		style = {},
		type = 'color',
		def ='#000000',
		autoComplete = 'off',
	} = s;

	// Значение
	const [val, setVal] = useState(v);
	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e);
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		if (v && v !== def)	setVal(v);
		else setVal(def)
	}, [v, def]);

	useEffect(() => {
		setErr(e);
	}, [e]);

	let cls = ['skin-input-base', 'input-base', 'input-color'];

	if (err) cls.push('error');
	cls = cls.join(' ');
	if (off) style.display = 'none';
	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="color" id={_id} onClick={onFocus}>
				<input
					id={_id}
					type='color'
					name={name}
					value={val}
					disabled={disabled}
					autoComplete={autoComplete}
					onChange={onChange}
					style={{ width: width }}
					title={title}
				/>
			</div>

			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	function onChange(e) {
		setStatus(false);
		setErr(null);
		setVal(e.target.value);

		setValue(name, e.target.value, type);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}
};

export default InputColor;
