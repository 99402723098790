import Item from './item';
import './style.css';

const Page = (props) => {
	const {
		idx,
		page,
		item,
		group,
		fld,
		text,
		table,
		action,
		values,
		errors,
		tbl,
	} = props;

	let cls = ['skin-cmp-page', 'cmp-page'];
	cls = cls.join(' ');
	if (!page?.list) return null;
	const pg = idx < page.list.length ? page.list[idx] : {};
	let a = item ?? [];

	a = a.filter((el) => !el.parent && el.pageId === pg._id);

	return (
		<div
			className={cls}
			data-info-title={`\nДанные по Странице`}
			data-info={`idx = ${idx}; page=${JSON.stringify(page)}`}
		>
			{a.map((el) => {
				return (
					<Item
						key={el._id}
						data={el}
						item={item}
						group={group}
						fld={fld}
						text={text}
						table={table}
						action={action}
						values={values}
						errors={errors}
						tbl={tbl}
					/>
				);
			})}
		</div>
	);
};

export default Page;
