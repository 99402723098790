import Fld from './fld'
import './style.css'

const Flds = (props) => {
    let cls = ['skin-flds', 'flds']
    cls = cls.join(' ')

    return (
        <div className={cls}>
            <Fld 
                label="Логин"
                name="login"
                id="login"
                pict="/skin/img/login_user.svg"
                holder="Введите логин"
            />
            <Fld 
                label="Почта"
                name="mail"
                id="mail"
                type="email"
                holder="Введите почту при регистрации"
            />
        </div>
    )
}

export default Flds