import { useState } from "react"
import './style.css'

const Info = props => {
    let cls = ['skin-info', 'info']
    cls = cls.join(' ')
    const [ok, setOk] = useState('open')
    const stl_ok = {backgroundImage: 'url(skin/img/login_' + ok + '.svg)'}
    const cls_ul = ok === 'open' ? 'off' : ''

    return (
        <div className={cls}>
            <ul className={cls_ul}>
                {props.info.map((el, i) => <li key={i}>{el.tit}: {el.val}</li>)}
            </ul>
            <div className='ok' style={stl_ok} onClick={okClick}></div>
        </div>
    )
    function okClick() {
        const typ = ok === 'close' ? 'open' : 'close'
        setOk(typ)
    }
}

export default Info