import Label from './lable';
import './style.css';

const InputDT = ({ s, v }) => {
	const { _id, name, title = '', width = '135px', style = {}, type, off } = s;

	let cls = ['skin-input-dt', 'input-base'];
	cls = cls.join(' ');

	const date = new Date(v);
	const value = v ? date.toLocaleString() : '';
	if(off) style.display = 'none'
	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="input">
				<input
					id={_id}
					type={type}
					name={name}
					value={value}
					disabled={true}
					style={{ width: width }}
					title={title}
				/>
			</div>
		</div>
	);
};

export default InputDT;
