import {dictionary} from 'skin/event'

// Действия в фильтрах
function filterAct(obj, table, story, action) {
	const ind = obj.ind ?? null
	switch (obj.action) {
		case 'clear':
			story.initFilter(table, action, true)
			break;
		case 'value':
			story.setFilter(obj.item, obj.value, ind)
			break;
		case 'dct':
			const o = {
				code: obj.dct,
				func: function (value) {
					const val = Array.isArray(value) ? value[1] : value
					const o = {
						type: 'filter',
						action: 'value',
						item: obj.item,
						value: {
							value: val,
							type: obj.operator
						}
					}
					action(o)
				}
			}
			dictionary(o)
			break;
		default:
			alert('[Функционал]Действие не определено')
	}
}

export default filterAct
