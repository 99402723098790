import './style.css';

const Error = ({ err, onClick, style }) => {
	if (!err) {
		console.log('Error: Массив ошибок отсутствует!');
		return null;
	}

	const stl = { backgroundImage: 'url(skin/img/attention-err.svg)' };
	const e = check()
	return (
		<ul className="error-message" style={style} onClick={onClick}>
			{e.map((el, i) => {
				return (
					<li key={i} className="d-flex ai-center">
						<span className="ico-e" style={stl} />
						{el}
					</li>
				);
			})}
		</ul>
	);

	function check() {
		if (typeof err != 'string') return err;
		return [err];
	}
};

export default Error;
