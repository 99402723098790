import { makeAutoObservable } from 'mobx';
import { space } from 'skin/event';

class Data {
	s = {
		// current: 'code',
		// code: {
		// 	info
		// 	table: {
		// 		code: {
		// 			page: 0,
		// 			row: 0,
		// 		},
		// 	},
		// 	page: 0,
		// },
	};
	constructor(props) {
		makeAutoObservable(this);
	}

	// получаем данные по текущей форме (Таблицы, тек.страница)
	get current() {
		const c = this.s.current;
		if (!c) return {};

		const r = this.s[c] ?? {};
		return r;
	}

	// Запоминаем текущую форму по ней будут выводится все данные
	setDef(code, table, info) {
		if (!code) return;
		this.s.current = code;
		// Инициализируем базовую структу для формы
		this.init(code, table, info);
	}

	// Установить текущую страницу для текущей формы
	setPage(p) {
		p = p < 1 ? 1 : p;
		const s = this.s;
		s[s.current].page = p;
	}

	// Установить текущуее представление таблицы
	setTblPg(tbl, p, l, action) {
		if (p < 1 || !p) p = 1;
		if (p > l) p = l;
		const s = this.s;
		s[s.current].table[tbl].page = p;

		const o = {
			type: 'form',
			action: 'reload',
			table: tbl,
		};

		action(o);
	}

	// Установить текущую строку таблицы
	setTblRow(data) {
		const { key, info, table, form } = data;
		const s = this.s;
		const row = s[s.current].table[table].row ?? '';
		if (row !== key) {
			s[s.current].table[table].row = key;
			return;
		}
		const o = {
			type: 'form',
			title: form.title ?? 'Форма',
			data: {
				code: form.code,
				info,
			},
		};
		space(o, true);
	}

	// Инициализируем пустую структуру для
	init(code, table, info = []) {
		if (this.s[code]) {
			this.s[code].info = info;
			return;
		}
		this.s[code] = {
			table: {},
			page: 1,
			info,
		};

		table.forEach((el) => {
			this.s[code].table[el.code] = {
				page: 1,
				row: 0,
			};
		});
	}

	// Удалить данные по текущему коду формы
	kill() {
		const s = this.s;

		delete s[s.current];
		s.current = '';
	}
}

const data = new Data();
export default data;
