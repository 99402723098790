import './style.css'

const Item = props => {
	let cls = [
		'skin-info-content-main-item',
		'info-content-main-item'
	]
	cls = cls.join(' ')
	const el = props.data
	if (!el.title) return (
		<tr className={cls}>
			<td collspan='2' className='info-topic'>{el.value}</td>
		</tr>
	)
	return (
		<tr className={cls}>
			<td className='info-title'>{el.title}</td>
			<td className='info-value'>{el.value}</td>
		</tr>
	)
}

export default Item
