import Win from './win';
import './style.css';
import Wait from 'skin/space/wait';
import Shadow from 'skin/space//shadow';
import Dict from 'skin/dict';
import Notify from 'skin/cmp/notify';

const Signup = ({ data }) => {
	let cls = ['skin-signup', 'signup-box'];
	cls = cls.join(' ');
	const stl = {
		backgroundImage: 'url(/task/img/pattern.png)',
		backgroundSize: 'cover',
	};
	return (
		<div className={cls} style={stl}>
			<div className="shadow"></div>

			<div className="container">
				<Win />
				<div className="phone">+7 (904) 408 94 94</div>
			</div>
			<Dict data={data.dictionary} />
			<Shadow data={data.wait.on} />
			<Wait data={data.wait} />
			<Notify data={data.notify} />
		</div>
	);
};

export default Signup;
