import './style.css'

const Left = ({action, title}) => {
	let cls = [
		'skin-ms-ttl-left',
		'ms-ttl-left'
	]
	cls = cls.join(' ')
	const st = {backgroundImage: 'url(skin/img/reload.svg)'}
	return (
		<div className={cls}>
			<span className='ico-r' style={st} onClick={reload}></span>
			<p>{title}</p>
		</div>
	)

	// Перечитать мастер
	function reload() {
		const o = {
			type: 'master',
			action: 'reload'
		}
		action(o)
	}

}

export default Left
