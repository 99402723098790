import Group from 'skin/cmp/group';
import Fields from 'skin/cmp/fields';
import Table from 'skin/cmp/table';
import './style.css';
import { observer } from 'mobx-react-lite';
import Empty from 'skin/cmp/empty';

const Item = (props) => {
	const {
		data = {},
		item,
		group,
		fld,
		text,
		table,
		action,
		values,
		errors,
		tbl = {},
	} = props;
	const tp = !data.type ? 'text' : data.type;
	const a = props[tp];
	let o = null;

	if (a) {
		o = a.find((el) => data._id === el.itemId);
	}

	switch (tp) {
		case 'group':
			return (
				<Group
					id={data._id}
					data={o}
					item={item}
					group={group}
					fld={fld}
					text={text}
					table={table}
					action={action}
					values={values}
					errors={errors}
				/>
			);
		case 'fld':
			const vfld = values?.fld[o?.name] ?? '';
			const e = errors[o?.name];
			return <Fields data={o} action={action} v={vfld} e={e} />;
		case 'table':
			const vtable = values?.table[o?.code] ?? {};
			const story = tbl[o?.code];
			return <Table data={o} action={action} v={vtable} story={story} />;
		default:
			return <Empty />;
	}
};

export default observer(Item);
