import { stCol } from 'skin/dict/content/main/fn';
import './style.css';

const Col = (props) => {
	let cls = ['skin-dct-cnt-main-body-row-col', 'dct-cnt-main-body-row-col'];
	cls = cls.join(' ');
	const st = {}
	const name = props.type === '' ? 'itm' : props.type;
	if (props.item === props.label) {
		st.backgroundImage = `url(skin/img/dct_${name}.svg)`;
		st.paddingLeft = '20px';
	}
	const val =
		typeof props.data === 'boolean' ? props.data.toString() : props.data;
		const stDiv = stCol(props.width, props.item)
	return (
		<td className={cls} style={st} >
			<div title = {val} style = {stDiv} >{val}</div>
		</td>
	);
};

export default Col;
