import { status } from "skin/event/status";

function act(obj, ms, story, data, d, action) {
  console.log("[Ячейки - Мастер] событие");
  if (obj.id !== '66ea6e5005bd82001ec14413') return status.action
  
  const port = "4010";
  const href = data?.s?.values?.fld?.txtIp;
  if (href === "Не выбрано" || !href) return status.ignore;

  const url = "http://" + data?.s?.values?.fld?.txtIp + ":" + port;
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
  return status.ignore;
}

export default act;
