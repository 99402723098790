import api from 'skin/http';
import { space } from 'skin/event';
import { makeAutoObservable, runInAction } from 'mobx';
import { stackClear } from 'skin/event';

class Data {
	s = {};

	constructor(props) {
		makeAutoObservable(this);
	}

	// Получение данных пользователя
	get() {
		api.get('api/data/employee')
			.then((res) => {
				runInAction(() => {
					this.s = res.data.result;
				});
			})
			.catch((e) => {
				console.log('User get error', e);
			});
	}

	form() {
		const o = {
			type: 'form',
			title: 'Личный кабинет',
			data: {
				code: process.env.REACT_APP_USER_FORM ?? 'employee',
				info: [this.id],
			},
		};
		stackClear();
		space(o, true);
	}

	get id() {
		return this.s._id ?? '';
	}

	get code() {
		return this.s.code ?? '';
	}
	get companyId() {
		return this.s.companyId ?? '';
	}

	get short() {
		return this.s.short ?? false;
	}
	get adm() {
		return this.s.adm ?? false;
	}

	get blc() {
		return this.s.blc ?? 0;
	}
	get payDate() {
		return this.s.payDate ?? '';
	}
	// Форрмирование Имени пользователя
	get name() {
		const s = this.s;

		const name = s.name?.first;
		const surname = s.name?.last;
		let r = name ? name.charAt(0) + '. ' : '';
		r = r && surname ? r + surname : '';
		if (r === '') r = s.login;
		return r;
	}

	// Формирование url аватара сотрудника
	get avatar() {
		const s = this.s;
		return this.s.img
			? 'url(' + process.env.REACT_APP_TASK + 'company/' + s.code + '/img/' + s.img + ')'
			: 'url(skin/img/avatar.svg)';
	}
	// Формирование url логотипа компании
	get logo() {
		return this.s.logo;
	}
}

const data = new Data();
export default data;
