import { useContext } from 'react';
import { Cnt } from 'skin/context';
import form from 'skin/store/form';
import data from 'skin/store/form/data';
import Page from 'skin/cmp/page';
import './style.css';
import { observer } from 'mobx-react-lite';

const Body = () => {
	const { action, current, pgCount } = useContext(Cnt);

	let cls = ['skin-form-main-body', 'form-main-body'];
	cls = cls.join(' ');

	// Получаем данные из mobx form и data
	const { page, item, group, fld, table } = form.s;
	const { values, errors } = data.s;
	let p = [];
	if (pgCount) {
		p.push(0);
		if (pgCount > 1) p.push(current.page);
	}
	return (
		<div className={cls}>
			{p.map((el, i) => {
				return (
					<Page
						key={i}
						idx={el}
						page={page}
						item={item}
						group={group}
						fld={fld}
						table={table}
						action={action}
						values={values}
						errors={errors}
						tbl={current.table}
					></Page>
				);
			})}
		</div>
	);
};

export default observer(Body);
