const colors = [
	'blue',
	'green',
	'red',
	'navy',
	'olive',
	'lime',
	'purple',
	'black',
];
//Выбор цвета из массива
function selCl(i) {
	const cl = colors.length
	return i >= cl ? colors[i % cl] : colors[i]
}

export default selCl