import { status } from 'skin/event/status'
import { space } from 'skin/event'

// удаление привязки
const del = (obj, ms, story, data, d, action) => {
	const parent = JSON.parse(d)?.info?.[0]
	const sel = story.s.tnk_valve.table?.sectionRow?.row
	const sectionId = story?.s?.tnk_section?.info?.[0]

	// удаление привязок к другим секциям
	if (parent !== sel) return status.action
	// удаление родительской привязки
	const o = {
		type: 'form',
		title: 'Секция',
		data: {
			code: 'tnk_section',
			info: [sectionId],
			type: 'asgdkaljsdhlkjashkf',
		},
	}
	space(o, true, 'back')
	return status.action
}

export default del
