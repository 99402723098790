import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { status } from 'skin/event/status';
import './style.css';

import Footer from './footer';
import Title from './title';
import Page from 'skin/cmp/page';

import msAct from './act/master';
import dataAct from './act/data';
import tableAct from './act/table';

import user from 'task/store/action/master';
import ms from 'skin/store/master';
import data from 'skin/store/master/data';
import story from 'skin/store/master/story';

// Ленивая загрузка
// const Func = React.lazy((_) => import('skin/master/func'));

const Master = (props) => {
	const d = JSON.stringify(props.data);
	const code = props.data.code;

	// Пользовательский action
	const userAct = useCallback(
		(obj, ms, story, data, d, action) => {
			const fnc = user.s[code] ? user.s[code] : user.s.def;
			return fnc(obj, ms, story, data, d, action);
		},
		[code]
	);

	// Стандартный action Мастера по key
	const action = useCallback(
		(obj) => {
			const val = userAct(obj, ms, story, data, d, action);
			if (!val) return status.action;
			switch (obj.type) {
				case 'master':
					msAct(obj, d, action, ms, data, story);
					break;
				case 'table':
					tableAct(obj, action, ms, data, story);
					break;
				case 'data':
					dataAct(obj, data, action, d);
					break;
				default:
				// alert('[Мастер] Действие не определено');
			}
			return status.action;
		},
		[userAct, d]
	);

	useEffect(newCode, [d, action]);

	let cls = ['skin-master', 'master'];
	cls = cls.join(' ');
	const { page, item, group, fld, text, table } = ms.s;

	const pageLen = ms.pageLen;

	const current = story.current;
	const curPage = current.page;

	const title = ms.bookTitle;
	const list = ms.pages;
	const nextTitle = ms.getTitle(curPage);

	const { values, errors } = data.curData;

	return (
		<div className={cls}>
			<Title
				action={action}
				title={title}
				current={curPage}
				list={list}
			/>
			<div className="master-content">
				<Page
					idx={curPage}
					page={page}
					item={item}
					group={group}
					fld={fld}
					text={text}
					table={table}
					action={action}
					values={values}
					errors={errors}
					tbl={current?.table}
				/>
				<Footer
					pageCount={pageLen}
					action={action}
					current={curPage}
					nextTitle={nextTitle}
				/>
			</div>
		</div>
	);

	// Получение данных для мастера
	function newCode() {
		ms.getDef(d, action, story, data);
	}
};

export default observer(Master);
