
	// Действия со страницами
	function pageAct(obj, story, table, prp, action) {
		let all = table.s?.body?.page ?? 1
		prp = JSON.parse(prp)
		all = all - 1
		const page = story.page
		switch (obj.action) {
			case '=':
				story.setPage(obj.value)
				break;
			case 'home':
				story.setPage(0)
				table.getData(prp, story, action);
				break;
			case '-':
				if (!page) return
				story.setPage(page - 1)
				table.getData(prp, story, action);
				break;
			case '+':
				if (page < all) {
					story.setPage(page + 1)
					table.getData(prp, story, action);
				}
				break;
			case 'end':
				story.setPage(all)
				table.getData(prp, story, action);
				break;
			default:
				alert('[Страница]Действие не определено')
		}
	}

export default pageAct
