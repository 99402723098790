import {useContext} from 'react'
import {Cnt} from 'skin/context'
import Num from "./num";
import "./style.css";

const Page = props => {
	const {action, pages} = useContext(Cnt)
	if (!pages || pages < 2) return null;
	let cls = ["skin-tbl-page", "tbl-page"];
	cls = cls.join(" ");
	const st_home = { backgroundImage: "url(skin/img/page_home.svg)" };
	const st_up = { backgroundImage: "url(skin/img/page_up.svg)" };
	const st_dn = { backgroundImage: "url(skin/img/page_dn.svg)" };
	const st_end = { backgroundImage: "url(skin/img/page_end.svg)" };
	return (
		<div className={cls}>
			<span
				className="ico page-shift"
				style={st_home}
				onClick={_ => act("home")}
			></span>
			<span
				className="ico page-shift"
				style={st_up}
				onClick={_ => act("-")}
			></span>
			<Num />
			<span
				className="ico page-shift"
				style={st_dn}
				onClick={_ => act("+")}
			></span>
			<span
				className="ico page-shift"
				style={st_end}
				onClick={_ => act("end")}
			></span>
		</div>
	);
	// Изменить страницу
	function act(act) {
		const o = {
			type: "page",
			action: act,
		};
		action(o);
	}
};

export default Page;
