import Item from './item';
import './style.css';
import all from './list';

const Control = ({ action, data }) => {
	let cls = ['skin-cmptbl-com-control', 'tbl-com-control'];
	cls = cls.join(' ');

	return (
		<div className={cls}>
			{all.map((el) => (
				<Item key={el.id} data={el} click={click} />
			))}
		</div>
	);

	function click(item) {
		const o = {
			type: 'control',
			action: item,
			table: data.code,
		};
		action(o);
	}
};

export default Control;
