import { observer } from "mobx-react-lite";
import dict from "skin/store/dict";
import {useState} from 'react'
import Head from './head'
import Main from './main'
import {dictionary} from 'skin/event'
import './style.css'

const Content = props => {
	const [find, setFind] = useState(props.data.filter ?? '')
	// const [find, setFind] = useState('')
	let cls = [
		'skin-dct-content',
		'dct-content'
	]
	cls = cls.join(' ')
	const st = {backgroundImage: 'url(skin/img/close.svg)'}
	return (
		<div className={cls}>
			<Head find={{value: find, setFind}} />
			{/* <Main find={{value: find, setFind}} code={props.data.code} func={props.data.func} info={props.data.info} unauth={props.data.unauth} /> */}
			<Main find={{value: find, setFind}} data ={props.data} />
			<div className='dct-close' style={st} onClick={close}></div>
		</div>
	)
	// Закрыть справочник
	function close() {
		setFind('')
		dict.clear()
		dictionary()
	}
}

export default observer(Content)
