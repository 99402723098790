import {stCol} from 'skin/dict/content/main/fn'
import './style.css'

const Itm = props => {
	let cls = [
		'skin-dct-cnt-main-head-itm',
		'dct-cnt-main-head-itm'
	]
	const st = stCol(props.width, props.item)
	cls = cls.join(' ')
	return (
		<th className={cls} style={st}>
			{props.data}
		</th>
	)
}

export default Itm
