import Item from './item';
import './style.css';

const Head = (props) => {
	let cls = ['skin-cmptbl-main-head', 'tbl-main-head'];
	cls = cls.join(' ');
	const a = props.col;
	const mW = props.minWidth
	return (
		<div className={cls}>
			<table style ={{minWidth: mW}}>
				<thead>
					<tr>
						{a.map((el, i) => (
							<Item key={i} data={el} item={i} />
						))}
					</tr>
				</thead>
			</table>
		</div>
	);
};

export default Head;
