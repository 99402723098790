import './style.css';
import user from 'skin/store/user';


const Item = ({ click, data }) => {
	let cls = ['skin-frm-head-com-cnt-item', 'frm-head-com-cnt-item'];
	cls = cls.join(' ');
	const id = data.id;
	const title = data.title 
	const st = { backgroundImage: `url(skin/img/com_${id}.svg)` };
	let ttl = <span className="frm-head-com-cnt-itm-tit">{title}</span>
	if (user.short) ttl = null

	return (
		<div className={cls} onClick={() => click(id)}>
			<span className="ico" style={st} title={title}></span>
			{ttl}
		</div>
	);
};

export default Item;
