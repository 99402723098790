import { useContext } from 'react';
import { Cnt } from 'skin/context';

import { observer } from 'mobx-react-lite';
import form from 'skin/store/form';
import './style.css';

const Item = (props) => {
	let cls = ['skin-form-head-ttl-com-lst-item', 'form-head-ttl-com-lst-item'];

	const { action } = useContext(Cnt);

	if (props.item === props.current) cls.push('on');
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/tb_view_itm.svg)' };
	const i = props?.item ?? '<не определен>';
	const title = props?.data?.title ?? 'Экран ' + i;
	return (
		<li className={cls} style={st} onClick={choice}>
			{title}
		</li>
	);
	function choice() {
		form.close();
		if (props.item !== props.current) {
			const o = {
				type: 'page',
				action: '=',
				page: props.item,
			};
			action(o);
		}
		props.close();
	}
};

export default observer(Item);
