import './style.css';
import Row from './row';
import { observer } from 'mobx-react-lite';

const Body = ({ data, def, rowClick, current, minWidth, action }) => {
	let cls = ['skin-cmptbl-main-body', 'cmptbl-main-body'];
	cls = cls.join(' ');
	const rows = data?.list ?? [];
	return (
		<div className={cls}>
			<table style={{minWidth}}>
				<tbody>
					{rows.map((el, i) => (
						<Row
							id={el.key}
							key={el.key}
							data={el}
							current={current}
							rowClick={rowClick}
							def={def}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default observer(Body);
