import Head from './head'
import Body from './body'
import './style.css'

const Main = props => {
	let cls = [
		'skin-dct-cnt-main',
		'dct-cnt-main'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<Head />
			{/* <Body code={props.code} find={props.find} func={props.func} info={props.info} unauth={props.unauth}/> */}
			<Body  find={props.find} data={props.data}/>
		</div>
	)
}

export default Main
