const data = [
	{
		id: 'check',
		title: 'Все'
	},
	{
		id: 'edit',
		title: 'Редатировать'
	},
	{
		id: 'view',
		title: 'Просмотреть'
	},
	{
		id: 'add',
		title: 'Добавить'
	},
	{
		id: 'delete',
		title: 'Удалить'
	},
	{
		id: 'action',
		title: 'Функционал'
	},
	{
		id: 'filter',
		title: 'Фильтр'
	}
]

export default data
