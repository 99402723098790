import { observer } from "mobx-react-lite";
import menu from "skin/store/menu";
import "./style.css";

const Head = (props) => {
	let cls = ["skin-pg-mn-head", "pg-mn-head"];
	cls = cls.join(" ");
	const st_p = { backgroundImage: "url(skin/img/pg_mn_roll.svg)" };
	const el_p = menu.s.tree ? (
		<p style={st_p} onClick={menu.closeAll}>
			Свернуть элементы
		</p>
	) : null;
	let st_bt = menu.s.tree ? "close" : "open";
	let title = menu.s.tree ? "Закрыть меню" : "Открыть меню";
	st_bt = { backgroundImage: `url(skin/img/pg_mn_${st_bt}.svg)` };
	return (
		<div className={cls}>
			{el_p}
			<span className="ico-r" style={st_bt} onClick={menu.tree} title={title}></span>
		</div>
	);
};

export default observer(Head);
