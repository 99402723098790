import Fld from './fld';
import './style.css';

const Flds = (props) => {
	let cls = ['skin-flds', 'flds'];
	cls = cls.join(' ');
	return (
		<div className={cls}>
			<Fld
				label="Логин"
				name="login"
				id="login"
				pict="/skin/img/login_user.svg"
				holder="Введите логин"
			/>
			<Fld
				label="Пароль"
				name="password"
				id="password"
				pict="/skin/img/login_password.svg"
				type="password"
				holder="Введите пароль"
			/>
		</div>
	);
};

export default Flds;
