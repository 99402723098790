import {useContext} from 'react'
import {Cnt} from 'skin/context'
import './style.css';

const View = props => {
	let cls = [
		'skin-tbl-head-com-view',
		'tbl-head-com-view'
	]
	const func = props.data.func?.on
	const {action} = useContext(Cnt)
	if (func) cls.push('off')
	cls = cls.join(' ')
	const view = props.data.view
	const st = {backgroundImage: 'url(skin/img/tb_view.svg)'}
	return (
		<div className={cls} style={st} data-info={view.code} data-info-title='Представление' onClick={open}>
			{view.title}
		</div>
	)
	// Открыть список представлений
	function open() {
		if (func) return
		const o = {
			type: 'view',
			action: 'open'
		}
		action(o)
	}
}

export default View
