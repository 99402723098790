import './style.css';

const Num = ({ setPage, page, pages }) => {
	let cls = ['skin-cmptbl-page-num', 'tbl-page-num'];
	cls = cls.join(' ');
	page = page > pages ? 1 : page
	return (
		<div className={cls}>
			<input
				type="number"
				value={page}
				onChange={(e) => onChange(e.target.value)}
				min={1}
				max={pages}
			/>
			<label>из {pages}</label>
		</div>
	);
	// Перейти на нуказанную страницу
	function onChange(e) {
		const v = e > pages ? pages : e;
		setPage('=', v);
	}
};

export default Num;
