import { makeAutoObservable } from 'mobx'
import def from './def'
import p_msDwnlDump from './ms_dwnl_dump'

class Data {
	s = {
		def,
		p_msDwnlDump,
	}
	constructor(props) {
		makeAutoObservable(this)
	}
}

const data = new Data()
export default data
