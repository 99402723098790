import {message} from 'skin/event'
import def from 'skin/data/message'
import './style.css'

const Itm = props => {
	let cls = [
		'skin-mess-cnt-act-itm',
		'mess-cnt-act-itm',
		'btn'
	]
	cls = cls.join(' ')
	const type = props.data.type ?? 'ok'
	const val = props.data.value ?? value(type)
	const action = props.data.action ?? (_ => false)
	const st = {backgroundImage: `url(skin/img/mess_a_${type}.svg)`}
	return (
		<div className={cls} style={st} onClick={run}>
			{val}
		</div>
	)
	// Запуск экшона
	function run() {
		if (action() === true) return
		message()
	}
	// Название кнопки
	function value(type) {
		const val = def.btn[type]
		if (val) return val
		return 'Вариант ' + props.item
	}
}

export default Itm
