import Col from './col'
import './style.css'

const Row = props => {
	let cls = [
		'skin-dct-cnt-main-body-row',
		'dct-cnt-main-body-row'
	]
	cls = cls.join(' ')
	const w = props.width
	const t = props.data.type ?? ''
	const a = props.data.list
	const v = a.length > 1 ? 1 : 0
	return (
		<tr className={cls} onClick={props.click}>
			{a.map((el, i) => <Col key={i} data={el} width={w} type={t} item={i} label={v} />)}
		</tr>
	)
}

export default Row
