// Действия в функционале
function actionAct(obj, form, story) {
	switch (obj.action) {
		case 'click':
			form.fncClick(obj.id, story.current.info);
			break;
		case 'close':
			form.func();
			break;
		case 'home':
			form.fncHome();
			break;
		case 'up':
			form.fncUp();
			break;
		default:
			alert('[Функционал] Действие не определено');
	}
}

export default actionAct;
