import Form from 'skin/form'
import Menu from 'skin/menu'
import Table from 'skin/table'
import Master from 'skin/master'

const Case = ({space}) => {
	const type = space.type
	switch (type) {
		case 'menu':
			return <Menu />
		case 'table':
			return <Table data={space.data} />
		case 'form':
			return <Form data={space.data} />
		case 'master':
			return <Master data={space.data} />
		default:
			return <p>Документ не определен</p>
	}
}

export default Case
