import Left from './left';
import Right from './right';
import './style.css';

const Header = props => {
	let cls = ['skin-header', 'header', 'wrapper'];
	cls = cls.join(' ');
	return (
		<header className={cls}>
			<Left mode={props.mode}/>
			<Right />
		</header>
	);
};

export default Header;
