import { status } from 'skin/event/status';
// import { space } from 'skin/event'

// удаление привязки
const reload = (obj, ms, story, data, d, action) => {
	const parent = JSON.parse(d)?.info?.[0];
	const sel = story.s.tnk_valve.table?.sectionRow?.row;
	if (parent === sel) return status.ignore;
	return status.action;
};

export default reload;
