import React, { Suspense } from 'react'
import Head from './head'
import Loading from 'skin/cmp/loading'
import './style.css';

const Main = React.lazy(_ => import('./main'))

const Menu = props => {
	let cls = [
		'skin-menu',
		'menu'
	]
	cls = cls.join(' ')
	return (
		<div className={cls} data-info='Системное меню'>
			<Head />
			<Suspense fallback={Loading()}>
				<Main />
			</Suspense>
		</div>
	)
}

export default Menu
