import Item from './item'
import './style.css'

const Main = props => {
	let cls = [
		'skin-info-content-main',
		'info-content-main'
	]
	cls = cls.join(' ')
	const a = props.data ?? []
	return (
		<div className={cls}>
			<table>
				<tbody>
					{a.map((el, i) => <Item key={i} data={el} />)}
				</tbody>
			</table>
		</div>
	)
}

export default Main
