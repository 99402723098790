import Num from './num';
import './style.css';

const Page = ({ action, page, pages, setPage }) => {
	if (pages < 2) return null;
	let cls = ['skin-cmptbl-page', 'tbl-page'];
	cls = cls.join(' ');
	const st_home = { backgroundImage: 'url(skin/img/page_home.svg)' };
	const st_up = { backgroundImage: 'url(skin/img/page_up.svg)' };
	const st_dn = { backgroundImage: 'url(skin/img/page_dn.svg)' };
	const st_end = { backgroundImage: 'url(skin/img/page_end.svg)' };
	return (
		<div className={cls}>
			<span
				className="ico page-shift"
				style={st_home}
				onClick={(_) => setPage('home')}
			></span>
			<span
				className="ico page-shift"
				style={st_up}
				onClick={(_) => setPage('-')}
			></span>
			<Num action={action} page={page} pages={pages} setPage={setPage} />
			<span
				className="ico page-shift"
				style={st_dn}
				onClick={(_) => setPage('+')}
			></span>
			<span
				className="ico page-shift"
				style={st_end}
				onClick={(_) => setPage('end')}
			></span>
		</div>
	);
};

export default Page;
