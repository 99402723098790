import { message } from 'skin/event';
import { kit } from 'skin/data/message';

// Действия в командах
function controlAct(obj, table, story, prp, action) {
	prp = JSON.parse(prp);
	const mesTitle = table.s?.head?.title ?? '<Не определено>';
	const aBtn = kit('yesno');
	let o;
	switch (obj.action) {
		case 'edit':
			const key = story.key;
			table.rowClick(key, story, prp);
			break;
		case 'filter':
			story.setPage(0);
			table.getData(prp, story, action);
			break;
		case 'action':
			table.func(true);
			break;

		case 'view':
			message(`Функция не активна`, mesTitle);
			break;

		//удалить запись
		case 'delete':
			o = { type: 'table', action: 'delete' };
			aBtn[0].action = (_) => action(o);
			message(
				'Вы хотите удалить документ из таблицы?',
				mesTitle,
				aBtn,
				'?'
			);
			break;
		case 'add':	
			o = { type: 'table', action: 'add' };
			aBtn[0].action = (_) => action(o);
			message('Вы хотите добавить документ?', mesTitle, aBtn, '?');
			break;
		default:
		// alert('[Команды]' + obj.action)
	}
}

export default controlAct;
