import {shadow} from 'skin/event'

// Действия в представлениях
function viewAct(obj, table, story, action) {
	switch (obj.action) {
		case 'open':
			open()
			break;
		case 'close':
			shadow()
			table.viewOn()
			break;
		case 'set':
			story.setView(obj.item, table, action)
			shadow()
			table.viewOn()
			break;
		default:
			alert('[Представление]Действие не определено')
	}
	// Показать представления
	function open() {
		const a = table.s?.view?.list ?? []
		if (!a.length) {
			alert('Представлений нет')
			return
		}
		shadow(true)
		table.viewOn()
	}
}

export default viewAct
