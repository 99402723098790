import {useContext} from 'react'
import {Cnt} from 'skin/context'
import './style.css'

const Item = props => {
	const {action} = useContext(Cnt)
	let cls = [
		'skin-tbl-head-com-lst-item',
		'tbl-head-com-lst-item'
	]
	if (props.item === props.current) cls.push('on')
	cls = cls.join(' ')
	const st = {backgroundImage: 'url(skin/img/tb_view_itm.svg)'}
	return (
		<li className={cls} style={st} onClick={click}>
			{props.data.title}
		</li>
	)
	function click() {
		const o = {
			type: 'view',
			action: 'set',
			item: props.item
		}
		action(o)
	}
}

export default Item
