import { crtEvent } from 'skin/event';
import Note from './note';
import './style.css';

const Notify = ({ data = [] }) => {
	if (!data.length) return null;
	let cls = ['skin-cmp-notify'];
	cls = cls.join(' ');
	return (
		<div className={cls}>
			{data.map((el) => (
				<Note data={el} key={el.id} del={del} />
			))}
		</div>
	);

	function del(id) {
		const obj = {
			action: 'notify',
			type: 'del',
			data: id,
		};
		crtEvent(obj);
	}
};

export default Notify;
