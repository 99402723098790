import { status } from 'skin/event/status'
import table from './table'

const sensor = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj)
	switch (obj.type) {
		case 'table':
            return table(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default sensor