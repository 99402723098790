import { status } from 'skin/event/status'
import del from './del'
import click from './click'

export default function table(obj, ms, story, data, d, action) {
	switch (obj.action) {
		case 'delete':
			return del(obj, ms, story, data, d, action)
		case 'row':
			return click(obj, ms, story, data, d, action)
		default:
	}
	return status.action
}
