import { useEffect } from 'react';
import './style.css';

const t = {
	success: 'Выполнено.',
	info: 'Информация.',
	error: 'Ошибка!',
	warning: 'Предупреждение!',
};

const Note = ({ data, del }) => {
	let { type = 'info', id, txt, title = null } = data;
	useEffect(() => {
		const t = setTimeout(del, 10000, id);
		return () => clearTimeout(t);
	}, [del, id]);

	let cls = ['skin-cmp-notify-note'];
	cls.push('n-' + type);
	cls = cls.join(' ');
	const btn = { backgroundImage: 'url(skin/img/close.svg)' };
	if (typeof txt === 'string') txt = [txt];
	if (!title) title = t[type];
	return (
		<div className={cls}>
			<p>{title}</p>
			{txt.map((el, i) => (
				<p key={i}>{el}</p>
			))}
			<span className="ico-r" style={btn} onClick={() => del(id)}></span>
		</div>
	);
};
export default Note;
