import { observer } from "mobx-react-lite";
import menu from "skin/store/menu";
import "./style.css";

const Head = (props) => {
	let cls = ["skin-mn-head", "mn-head"];
	cls = cls.join(" ");
	const title = getTitle(menu.s.parent ?? "");
	const st_reload = { backgroundImage: "url(skin/img/mn_reload.svg)" };
	const st_home = { backgroundImage: "url(skin/img/mn_home.svg)" };
	const st_up = { backgroundImage: "url(skin/img/mn_up.svg)" };
	return (
		<div className={cls}>
			<span className="ico-r" style={st_reload} onClick={reload} title="Обновить данные"></span>
			<span className="ico-r" style={st_home} onClick={home} title="Вернуться в меню"></span>
			<span className="ico-r" style={st_up} onClick={up} title="Назад"></span>
			<div className="mn-hd-dlm"></div>
			<div className="mn-hd-title">{title}</div>
		</div>
	);
};
// Перечитать меню
function reload() {
	menu.getMenu()
}
// В начало
function home() {
	menu.home();
}
// На уровень выше
function up() {
	menu.mnUp();
}
// Название уровня
function getTitle(id) {
	if (id === "") return "Меню";
	const o = menu.s.list.find((el) => el._id === id);
	return o.title;
}

export default observer(Head);
