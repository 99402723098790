import Col from './col';
import './style.css';

const Row = ({ id, data, current, rowClick, def }) => {
	let cls = ['skin-cmptbl-main-body-row', 'tbl-main-body-row'];
	if (id === current) cls.push('on');
	cls = cls.join(' ');

	const row = data.list ?? [];
	if (row.length !== def.length) {
		console.error(
			`Кол-во значений в структуре (${def.length}) не равно кол-ву полученных данных (${row.length})`
		);
	}

	return (
		<tr
			className={cls}
			onClick={() => rowClick(data.key)}
			id={data.key}
			data-info-title={'Данные по строке ' + data.key}
			data-info={JSON.stringify(data.info)}
		>
			{row.map((el, i) => (
				<Col key={i} data={el} def={def[i]} />
			))}
		</tr>
	);
};

export default Row;
