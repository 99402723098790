import Left from './left'
import Right from './right'
import './style.css'

const Title = ({action, title, current, list}) => {
	let cls = [
		'skin-ms-title',
		'ms-title'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<Left action={action} title={title}/>
			<Right action={action} current={current} list={list}/>
		</div>
	)
}

export default Title
