import { useEffect } from 'react';
import Refresh from 'skin/auth/refresh';
import './style.css';
import { type } from 'skin/event';

const Wait = (props) => {
	useEffect(() => {
		if (localStorage.getItem('token')) {
			Refresh();
		} else {
			type('login');
		}
	}, []);

	let cls = ['skin-wait', 'wait-box'];
	cls = cls.join(' ');
	return <div className={cls}>Ожидание...</div>;
};

export default Wait;
