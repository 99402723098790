import {message} from 'skin/event'
import Title from './title'
import Main from './main'
import Action from './action'
import './style.css'

const Content = props => {
	let cls = [
		'skin-mess-content',
		'mess-content'
	]
	cls = cls.join(' ')
	const st = {backgroundImage: 'url(skin/img/close.svg)'}
	return (
		<div className={cls}>
			<Title data={props.data} />
			<Main text={props.data.text} />
			<Action action={props.data.action} />
			<div className='mess-cnt-close' style={st} onClick={close}></div>
		</div>
	)
}
function close() {
	message()
}

export default Content
