import './style.css';

const Head = props => {
	let cls = [
		'skin-tbl-head-com-lst-head',
		'tbl-head-com-lst-head'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			Представления
		</div>
	)
}

export default Head
