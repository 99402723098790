import Control from './control'
import Page from 'skin/table/page'
import './style.css'

const Command = props => {
	let cls = [
		'skin-tbl-command',
		'tbl-command'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<Control />
			<Page />
		</div>
	)
}

export default Command
