// Действия в фильтрах
function tableAct(obj, form, story, data, action) {
	switch (obj.action) {
		// Обработка клика по строке в таблице
		case 'row':
			story.setTblRow(obj);
			break;
		case 'delete':
			form.delRow(obj.row, obj.table, action);
			break;
		case 'add':
			form.addTbl(story, data, obj.table);
			break;
		default:
			alert('[Форма] [Таблица] Действие не определено');
	}
}

export default tableAct;
