import { observer } from 'mobx-react-lite'
import menu from 'skin/store/menu'
import './style.css'

const Command = props => {
	let cls = [
		'skin-command',
		'command'
	]
	cls = cls.join(' ')
	const st_bt = {backgroundImage: 'url(skin/img/command.svg)'}
	return (
		<div className={cls}>
			<form onSubmit={comm}>
				<button className='clr-t1' style={st_bt}></button>
				<input type='text' placeholder='Дайте команду' />
			</form>
		</div>
	)
	// Обработка команды
	function comm(e) {
		e.preventDefault()
		const o = e.target.querySelector('input')
		if (o.value === '') {
			alert('Нужно указать команду')
			return
		}
		menu.findCommand(o.value)
		o.value = ''
	}
}

export default observer(Command)
