import './style.css';

const lnk = (props) => {
	let cls = ['skin-links-lnk', 'lnk'];
	cls = cls.join(' ');
	const st = { backgroundImage: `url(${props.pict})` };
	return (
		<div className={cls}>
			<a id={props.id} href={props.href}>
				<div className='pict' style={st}></div>
				{props.title}
			</a>
		</div>
	);
};

export default lnk;
