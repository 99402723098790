import { type } from 'skin/event';
import axios from 'axios';

function Refresh() {
	axios
		.get(`${process.env.REACT_APP_TASK}api/auth/refresh`, {
			withCredentials: true,
			headers: {
				Task: process.env.REACT_APP_CODE,
			},
		})
		.then(function (response) {
			localStorage.setItem('token', response.data.accessToken);
			localStorage.setItem('user', response.data.user);
			type('ok');
		})
		.catch((e) => {
			console.log('Refresh error', e.response?.data);
			localStorage.clear('token');
			localStorage.clear('user');
			type('login');
		});
}

export default Refresh;
