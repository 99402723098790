import { status } from "skin/event/status";
import { space } from "skin/event";

function table(obj, ms, story, data, d, action) {
  const rows = data?.s?.values?.table?.tnk_defWithout?.list;
  
  // Количество колонок у mark
  const cnt = rows.find((el) => el.key === obj.key)?.list[5];

let o = {
    type: "form",
    title: "Колонки",
    data: {
      code: "tnk_defWithout",
    },
  };
  switch (true) {
    case cnt <= 1: {
      o.data.info = [obj.key];
      break;
    }
    case cnt > 1: {
      o.data.code = "tnk_mlWithout";
      o.data.info = [obj.key];
      break;
    }
    default:
      return status.action;
  }
  space(o, true);
  return status.ignore;
}
export default table;
