import "./style.css";

const Empty = ({type}) => {
	let cls = ["skin-cmp-empty", "cmp-empty"];
	cls = cls.join(" ");
	return (
		<div className={cls}>
			<span>{type}</span> не определен
		</div>
	);
};

export default Empty
