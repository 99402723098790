import { dictionary } from 'skin/event';
// Действия
function dataAct(obj, data, story, action) {
	const act = obj.action ?? null;
	switch (act) {
		case 'setInput':
			data.setValue(obj.name, obj.value, action);
			break;
		case 'setImg':
			data.setImg(obj.name, obj.value);
			break;
		case 'setFile':
			data.setFile(obj.name, obj.value);
			break;
			// Открытие справочника
		case 'dictionary':
			obj.info = story.current.info
			dictionary(obj)
			break;
		case 'setEnd': 
			break
		default:
			alert('[Форма] [Данные] Действие не определено');
	}
}

export default dataAct;
