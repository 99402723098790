import Empty from 'skin/cmp/empty';
import Item from 'skin/cmp/page/item';
import './style.css';

const Group = (props) => {
	let cls = ['skin-cmp-group', 'cmp-group'];

	if (!props.data) return <Empty type={'group'} />;
	const grp = props.data ?? {};
	const title = grp?.title;
	if (grp?.border) cls.push('border');

	cls = cls.join(' ');
	const itm = props.item.filter((el) => el.parent === props.id);
	const t = grp?.align ?? 'left';
	const width = grp?.width ?? null
	const height = grp?.height ?? null
	const dsp = grp?.display ?? null
	const st = {
		textAlign: t,
		alignItems: t,
		minWidth: width ?? '150px',
		display: dsp ?? 'table',
		height,
		padding: '5px',
		justifyContent: grp?.justifyContent ?? null,
		margin: grp?.margin ?? null
	}
	return (
		<div
			className={cls}
			style={st}
		>
			{title ? <div className="title">{title}</div> : null}
			{itm.map((el) => {
				return (
					<Item
						key={el._id}
						data={el}
						item={props.item}
						group={props.group}
						fld={props.fld}
						text={props.text}
						table={props.table}
						action={props.action}
						values={props.values}
						errors={props.errors}
					/>
				);
			})}
		</div>
	);
};

export default Group;
