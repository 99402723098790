import form from 'skin/store/form';
import './style.css';
import { shadow } from 'skin/event';
import { observer } from 'mobx-react-lite';

const View = ({ setOn, current, pgCount }) => {
	let cls = ['skin-form-head-ttl-com-view', 'form-head-ttl-com-view'];
	cls = cls.join(' ');

	if (!pgCount || pgCount < 3) return null;
	// Данные по текущей странице
	const view = form.getPage(current.page);

	let title = view?.title ?? '';

	if (current.page && !title) {
		title = 'Экран ' + current.page;
	}

	const st = { backgroundImage: 'url(skin/img/tb_view.svg)' };
	return (
		<div className={cls} style={st} onClick={open}>
			{title}
		</div>
	);
	function open() {
		shadow(true);
		setOn(true);
	}
};

export default observer(View);
