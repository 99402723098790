import './style.css';
import { dictionary } from 'skin/event';

const Fld = (props) => {
	let cls = ['skin-flds-fld', 'flds-fld'];
	cls = cls.join(' ');
	const st = { backgroundImage: `url(${props.pict})` };
	const img = props.dCode? (
		<div className="input-ico" style={ { backgroundImage: 'url("skin/img/dct_code.svg")' }} onClick={icoClick}></div>
	) : null
	
	return (
		<div className={cls}>
			<p>{props.label}</p>
			<div style={st}>
				<label>
					<input
						id={props.id}
						name={props.name}
						type={props.type}
						minLength={props.minlength}
						maxLength={props.maxlength}
						placeholder={props.holder}
						required={props.required === 'true'}
						disabled={props.disabled === 'true'}
					/>
					{/* Иконка */}
				{img}
				</label>
			</div>	
		</div>
	);

	function icoClick() {
		if (!props.dCode) return;
		// Отображаем дешифратор
		const o = { code: props.dCode, func: setV, unauth: true};
		dictionary(o);
		function setV(v) {
			const id =  document.getElementById(props.id)
			id.value = v
		}
		
	}
};

export default Fld;
