import { status } from 'skin/event/status';
import btn from './btn';


const msDwnlDump = (obj, ms, story, data, d, action) => {
    console.log('@[Ячейки - Мастер] событие', obj);
    switch (obj.type) {
        case 'master':
            return btn(obj, ms, story, data, d, action);
        default:
            return status.action;
    }
};

export default msDwnlDump;
