import { Polyline, Tooltip, Popup } from 'react-leaflet';
import selCl from '../colors';

const pln = ({ el, i = 0 }) => {
	const c = el.color ? el.color : selCl(i);
	const color = { color: c };
	const tooltip = el.tp ? (
		<Tooltip
			direction={el.tp.direction ?? 'center'}
			offset={el.tp.offset ?? [0, 0]}
			opacity={el.tp.opacity ?? 0.6}
			permanent={el.tp.pernament ?? true}
		>
			{el.name}
		</Tooltip>
	) : null;
	const popup = el.pp ? <Popup>{el.pp}</Popup> : null;
	return (
		<Polyline pathOptions={color} positions={el.geo}>
			{tooltip}
			{popup}
		</Polyline>
	);
};

export default pln;
