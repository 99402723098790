import "./style.css";

const Loading = props => {
	let cls = ["skin-cmp-loading", "cmp-loading"];
	cls = cls.join(" ");
	return (
		<div className={cls}>
			Загрузка данных...
		</div>
	);
};

export default Loading
