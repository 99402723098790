import { stackBak, stackClear } from "skin/event";
import "./style.css";

const Crumb = (props) => {
	const crb = props.stack;

	let cls = ["skin-crumb", "crumb", "d-flex", "ai-center"];
	cls = cls.join(" ");

	const all = crb.length;
	if (all === 0) return <article className={cls}></article>;

	const stl1 = { backgroundImage: "url(skin/img/home_1.svg)" };
	const stl2 = { backgroundImage: "url(skin/img/back-arrow.svg)", marginLeft: '15px'};

	return (
		<article className={cls}>
			<span
				className="ico-r"
				style={stl1}
				onClick={() => stackClear()}
			></span>
			<span
				className="ico-r"
				style={stl2}
				onClick={() => stackBak()}
			></span>

			{crb.map((el, i) => {
				return (
					<span
						key={i}
						onClick={() => crumb(i)}
						className="pr-1 breadcrumb"
					>
						{el.title}
					</span>
				);
			})}
		</article>
	);

	function crumb(n) {
		alert("Переход по крошке " + n);
	}
};

export default Crumb;
