import { Cnt } from 'skin/context';
import { useContext } from 'react';
import Item from './item';
import { status } from 'skin/event/status';
import './style.css';
import list from 'skin/data/form/control'; //Базовый набор команд

const Control = () => {
	const { action } = useContext(Cnt);
	const l = list ? list : [];

	let cls = ['skin-frm-com-control', 'frm-com-control'];
	cls = cls.join(' ');
	return (
		<div className={cls}>
			{l.map((el) => {
				return <Item key={el.id} data={el} click={click} />;
			})}
		</div>
	);

	function click(id) {
		const o = {
			type: 'control',
			action: id,
		};
		const act = action(o);
		if (act === status.ignore) return;
	}
};

export default Control;
