import api from 'skin/http';
import { type } from 'skin/event';

function Logout() {
	api.post('api/auth/logout')
		.then(function (response) {
			localStorage.removeItem('token');
			type();
		})
		.catch((e) => {
			console.log('error', e.response?.data);
		});
}

export default Logout;
